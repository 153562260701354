.tv-hosts {
  background-color: $blue-main;
  padding-bottom: 48px;
  overflow: hidden;
  margin-bottom: 40px;

  @include md {
    padding-bottom: 40px;
    margin-bottom: 32px;
  }

  // container
  >div {
    position: relative;
  }

  .video-item {
    a {
      color: $white;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    margin-top: 48px;

    @include sm {
      margin-top: 16px;
    }
  }

  &__title {
    @include font(48, 58.5px);
    color: $blue-main-border;
    margin-bottom: 24px;

    @include md {
      padding-left: 0;
      @include font(24, 29.25px);
      margin-bottom: 0;
    }
  }

  &__imagesWrapper {
    border-bottom: solid 1px #979EA9;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      @include size(1440px, 660px);
      background-size: cover;
      background-image: url('/src/images/video/circle/desktop.png');

      @include md {
        @include size(1024px, 770px);
        bottom: -58%;
        background-image: url('/src/images/video/circle/tablet.png');
      }

      @include sm {
        @include size(375px, 351px);
        bottom: -15px;
        background-image: url('/src/images/video/circle/mobile.png');
      }
    }
  }

  &__slider {
    margin-top: 48px;

    @include md {
      margin-top: 32px;

      .splide {
        margin-left: -24px;
        padding-left: 24px;
      }
    }

    .splide__track {
      padding-bottom: 35px;

      @include md {
        padding-bottom: 32px;
      }
    }

    .splide__arrows {
      margin-right: auto;
      margin-left: 0;
      user-select: none;
    }
  }

}

.sliderScale {
  position: relative;
  max-width: 1440px;
  width: 100%;
  --slide-height: 19rem;
  // --slide-spacing: 100px;
  --slide-size: 14.2857%;
  user-select: none;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  overflow: hidden;

  @include lg-1400 {
    max-width: 100%;
  }

  &__viewport {
    padding-top: 30px;
    overflow: hidden;
    width: 100%;
    max-height: 624px;
    position: relative;

    @include md {
      max-height: 455px;
    }

    @include sm {
      max-height: 320px;
      padding-top: 24px;
    }


    &::before,
    &::after {
      content: '';
      position: absolute;
      @include size(300px, 100%);
      background: linear-gradient(90deg, rgba(24, 40, 64, 0) 0%, rgba(24, 40, 64, 0.35) 32.81%, rgba(24, 40, 64, 0.65) 100%);
      transform: rotate(180deg);
      z-index: 1;
      top: 0;
      pointer-events: none;

      @include md {
        @include size(150px, 100%);
      }

      @include sm {
        @include size(85px, 100%);
      }
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
      transform: rotate(0deg);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    margin-left: 0px;
    touch-action: pan-y pinch-zoom;
    // margin-left: calc(var(--slide-spacing) * -1);
    // padding: 30px 0;
    // position: relative;
    // z-index: 1;
    // pointer-events: none;
  }

  &__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    // padding-left: var(--slide-spacing);
    position: relative;
    z-index: 0;
    @include size(207px, fit-content);
    min-width: 207px;
    max-width: 207px;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;

    &:nth-child(3) {
      img {
        max-height: 530px;
      }
    }

    @include md {
      width: 150px;
      min-width: 150px;
      max-width: 150px;
    }

    @include sm {
      width: 110px;
      min-width: 110px;
      max-width: 110px;
    }

    img {
      width: auto;
      height: 560px;
      transition: filter 0.3s ease;

      // object-fit: contain;
      @include md {
        height: 400px;
      }

      @include sm {
        height: 260px;
      }
    }

    &.is-active {
      img {
        filter: drop-shadow(-2px 2px 8px rgba(255, 117, 52, 1));
      }

      .host-img-slider-text {
        color: $main;
      }
    }
  }

  .host-img-slider-text {
    margin-bottom: 60px;
    @include center-flex();
    color: $white;
    @include font(24px, 30px, 700);
    border-right: solid 1px rgba(210, 210, 210, 0.4);
    padding: 0 30px;
    transition: color 0.3s ease;
    min-width: 175px;

    @include md {
      @include font(14px, 18px, 700);
      padding: 0 20px;
    }

    @include sm {
      min-width: auto;
      margin-bottom: 30px;
    }

    &.is-active {
      color: $main;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    @include size(180px, 100px);
    background: linear-gradient(270deg, #182840 53.88%, rgba(24, 40, 64, 0) 100%);
    transform: rotate(180deg);
    z-index: 1;
    top: 0;
    pointer-events: none;

    @include md {
      @include size(60px, 100px);
    }
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: rotate(0deg);
  }

  &__arrow {
    background-color: transparent;
    border: none;
    position: absolute;
    z-index: 10;
    top: 0;
    // transform: translateY(50%);
    cursor: pointer;

    @include md {
      top: 15px;

      svg {
        @include size(30px, 54px);
      }
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}