.video-project {
  display: flex;
  flex-direction: column;
  color: $white;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    // min-height: 171px;
  }

  &__text {
    height: fit-content;
  }

  &_large {
    img {
      margin-bottom: 8px;

      @include md {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    @include font(20px, 26.5px, 700);
    margin-top: 8px;

    @include md {
      @include font(18px, 22px);
    }

    a {
      color: $blue-main;
    }
  }

  &__description {
    @include font(16px, 24px, 400);
    color: $white;
    margin-top: 4px;
    display: block;
    position: relative;
    @include line-clamp(3);

    &:first-child {
      @include sm {
        @include line-clamp(5);
      }
    }

    >span {
      color: $main;
      @include font(14px, 21px, 700);
      background-color: $blue-main;
    }

    @include md {
      margin-top: 8px;
    }
  }
}